<template>
  <div class="flex-row footer-container">
    <!-- footer信息 -->
    <div class="flex-row container-box">
      <div class="flex-col container-text" v-if="judgeIsSHow">
        <div class="flex-row text-item">
          <div class="flex-col item-row">
            <div class="item-col">
              <span>客服电话:</span>
            </div>
            <div class="item-col">
              <span><a :href="'tel: 400-0571-123'">400-0571-123</a></span>
            </div>
          </div>
          <div class="flex-col item-row">
            <div class="item-col">
              <span>客户意见:</span>
            </div>
            <div class="item-col">
              <span>support@2kai.cn</span>
            </div>
          </div>
        </div>
        <div class="flex-row text-item">
          <div class="flex-col item-row">
            <div class="item-col">
              <span>公司地址:</span>
            </div>
            <div class="item-col">
              <span style="display: block; width: 282px;">浙江省杭州市萧山区民和路479号国泰科技大厦2单元15楼</span>
            </div>
          </div>
          <div class="flex-col item-row">
            <div class="item-col">
              <span>供应商合作:</span>
            </div>
            <div class="item-col">
              <span>business@2kai.cn</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row container-qr-code">
        <div class="flex-col code-item">
          <div class="flex-content qrcode-img">
            即将上线
          </div>
          <div class="qrcode-text">
            <span>扫码下载APP</span>
          </div>
        </div>
        <div class="flex-col code-item">
          <div class="flex-content qrcode-img">
            暂无
          </div>
          <div class="qrcode-text">
            <span>关注公众号</span>
          </div>
        </div>
      </div>
      <!-- 底部公司信息 -->
      <div class="flex-row company-data">
        <span>Copyright © 2024 杭州妤点数据科技有限公司 版权所有 </span>
        <span> <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024090244号-3</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  computed: {
    judgeIsSHow () {
      // if (this.$store.state.currentEquipment === 0) {
      //   return true
      // }
      return this.$store.state.currentEquipment === 0 || this.$route.path === '/contact'
    }
  }
}
</script>

<style lang='less' scoped>
@media screen and (max-width: 900px) {
  .footer-container {
    width: 100%;
    // height: 7.2rem;
    // background-image: url(../../assets/images/footer-bg.png);
    // background-size: cover;
    background: #b30d14;
    justify-content: center;
    position: relative;
    // padding-bottom: 1.5rem;
    .container-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container-text {
        width: 90%;
        margin-bottom: .1rem;
        .text-item {
          width: 100%;
          justify-content: space-between;
          color: #FFFFFF;
          .item-row {
            min-width:40%;
            justify-content: space-between;
            font-size: 14px;
            margin-top: .3rem;
          }
        }
      }
      .container-qr-code {
        width: 90%;
        justify-content: center;
        margin-top: .2rem;
        margin-bottom: 1rem;
        .code-item:last-child {
          margin-left: 20px;
        }
        .code-item {
          align-items: center;
          .qrcode-img {
            font-size: 14px;
            color: #333;
            border: 1px solid #999;
            width: 80px;
            height: 80px;
          }
          .qrcode-text {
            color: #FFFFFF;
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }
      .company-data {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: .1rem;
        font-size: 12px;
        color: #FFFFFF;
        flex-direction: column;
        align-items: center;
        a {
          width: 100%;
          color: #FFFFFF;
          text-decoration:none;
        }
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .footer-container {
  width: 100%;
  height: 289px;
  background-image: url(../../assets/images/footer-bg.png);
  background-size: cover;
  justify-content: center;
  padding-top: 57px;
  .container-box {
    width: 80%;
    max-width: 1200px;
    position: relative;
    .container-text {
      width: 75%;
      min-width: 500px;
      color: #FFFFFF;
      .text-item {
        width: 100%;
        margin-top: 44px;
        .item-row {
          width: 100%;
          justify-content: space-between;
          min-width: 230px;
          font-size: 18px;
        }
      }
      .text-item:first-child {
        margin: 0;
      }
    }
    .container-qr-code {
      justify-content: space-between;
      width: 25%;
      .code-item:last-child {
        margin-left: 20px;
      }
      .code-item {
        align-items: center;
        .qrcode-img {
          font-size: 14px;
          color: #333;
          border: 1px solid #999;
          width: 108px;
          height: 108px;
        }
        .qrcode-text {
          color: #FFFFFF;
          font-size: 12px;
          margin-top: 12px;
        }
      }
    }
    .company-data {
      width: 100%;
      position: absolute;
      bottom: 17px;
      justify-content: center;
      font-size: 19px;
      color: #FFFFFF;
      a {
        color: #FFFFFF;
        text-decoration:none;
        font-size: 19px;
      }
      span:last-child{
        margin-left: 20px;
      }
    }
  }

}
}

</style>
